type WeatherInformation = {
  currentTemperature: number,
  currentFeelsLikeTemperature: number,
  minTemperature: number,
  maxTemperature: number,
  minFeelsLikeTemperature: number,
  maxFeelsLikeTemperature: number,
  averageFeelsLikeTemperature: number,
  sunset: number,
  clothes: Clothes
} | null

// todo enums
type Clothes = {
  jacket: Jacket,
  top: Top,
  pant: Pant,
  shoe: Shoe,
  headdress: Headdress,
  accessories: Accessoire[]
}

type Jacket = "NONE" | "LIGHT_JACKET" | "BETWEEN_SEASONS_JACKET" | "WINTER_JACKET"

type Top =
  "TANK_TOP" | "T_SHIRT" | "LIGHT_SWEATER" | "SWEATER"

type Pant =
  "SHORTS" | "LONG_TROUSERS" | "LONG_TROUSERS_WITH_LONG_JOHNS"

type Shoe = "SANDALS" | "SHOES" | "BOOTS"

type Headdress = "NONE" | "CAP" | "BEANIE"

type Accessoire = "SCARF" | "GLOVES" | "UMBRELLA"

const jacketName: Record<Jacket, string> = {
  NONE: "Keine Jacke",
  LIGHT_JACKET: "Leichte Jacke",
  BETWEEN_SEASONS_JACKET: "Übergangsjacke",
  WINTER_JACKET: "Winterjacke",
}

const topName: Record<Top, string> = {
  LIGHT_SWEATER: "Leichter Pulli ",
  SWEATER: "Pulli",
  TANK_TOP: "Tanktop",
  T_SHIRT: "T-Shirt",
}

const pantName: Record<Pant, string> = {
  LONG_TROUSERS: "Lange Hose",
  LONG_TROUSERS_WITH_LONG_JOHNS: "Lange Hose mit langer Unterhose",
  SHORTS: "Kurze Hose",
}

const shoeName: Record<Shoe, string> = {
  BOOTS: "Stiefel",
  SANDALS: "Sandalen",
  SHOES: "Sneaker",
}

const headdressName: Record<Headdress, string> = {
  BEANIE: "Mütze",
  CAP: "Kappe oder Hut",
  NONE: "Keine Kopfbedeckung",
}

const accessoriesName: Record<Accessoire, string> = {
  GLOVES: "Handschuhe",
  SCARF: "Schal",
  UMBRELLA: "Regenschirm"
}

export type {WeatherInformation}

export {jacketName, topName, pantName, shoeName, headdressName, accessoriesName}
