// when changed, also change in index.html
const API_KEY = "AIzaSyA2Ke6igD9Ckka_J5OrHOjVkmeOcwxnAWQ"

const isLocalhost = false //window.location.href.startsWith("http://localhost")

let BACKEND_URL: string

if (isLocalhost) {
  BACKEND_URL = "http://localhost:8080/pulli"
} else {
  BACKEND_URL = "https://brauchichnenpulli-6xm664dlpa-ew.a.run.app/pulli"
}

export {API_KEY, BACKEND_URL}
