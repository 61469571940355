import React from "react";
import Skeleton from "react-loading-skeleton";

const RowSkeleton = () => <p><Skeleton width={280} height={"1.2rem"}/></p>

export const WeatherInformationSkeleton = () => {
  return (
    <div>xw

      <h3>Aktuelles Wetter lädt...</h3>
      { [
        1,
        2,
        3,
        4,
      ].map(() => (<RowSkeleton/>)) }

      <h3>KI-basierter Kleidungsvorschlag lädt...</h3>
      { [
        1,
        2,
        3,
        4,
      ].map(() => (<RowSkeleton/>)) }
    </div>

  );
};
