export type Breakpoint = "sm" | "md" | "lg"

const breakpoints: Record<Breakpoint, string> = {
  sm: "576",
  md: "768",
  lg: "1160"
}

export const up = (breakpoint: Breakpoint) => `
  @media (min-width: ${breakpoints[breakpoint]}px)
`;
