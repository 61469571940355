const mapResponseToSuggestions = (
  response: google.maps.places.AutocompleteResponse,
) => response.predictions.map((prediction) =>
  prediction.description,
);

export const getAutocompleteSuggestions = (input: string, callback: (suggestions: string[]) => void) => {
  const service = new google.maps.places.AutocompleteService();

  const request = {
    input,
    types: [
      "locality",
      "sublocality",
    ],
  }

  service.getPlacePredictions(request)
    .then(r => callback(mapResponseToSuggestions(r)))
}
