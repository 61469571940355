import {API_KEY} from "../config/config";

// https://github.com/SercanSercan/youtubeStuff/tree/master/google-maps
// https://storksnestblog.wordpress.com/2020/08/16/setting-up-google-maps-with-react-typescript/
export const loadMapApi = () => {
  const mapsURL = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places`;

  const googleMapScript = document.createElement('script');
  googleMapScript.src = mapsURL;
  googleMapScript.async = true;
  googleMapScript.defer = true;
  window.document.body.appendChild(googleMapScript);

  return googleMapScript;
};
