import {BACKEND_URL} from "../config/config";
import {WeatherInformation} from "../types/weatherInformation";
import axios from "axios";

export const getWeatherInformation = (lat?: number, lon?: number) => {
  if (!lat || !lon) {
    throw new Error("Received invalid coordinates")
  }

  return axios.get<WeatherInformation>(`${ BACKEND_URL }?lat=${ lat }&lon=${ lon }`)
}
