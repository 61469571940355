import {
  accessoriesName,
  headdressName,
  jacketName,
  pantName,
  shoeName,
  topName,
  WeatherInformation as WeatherInformationType,
} from "../../types/weatherInformation";
import React from "react";

export type WeatherInformationProps = {
  information: WeatherInformationType
  placeName: string
}

const WeatherInformation = ({ information, placeName }: WeatherInformationProps) => {
  if (!information) {
    return null
  }

  return (
    <div>
      { placeName ? <h3>Aktuelles Wetter in { placeName }:</h3>
        : <h3>Aktuelles Wetter</h3> }
      <p>🌡 Temperatur: { information.currentTemperature }ºC</p>
      <p>
        { information.currentFeelsLikeTemperature < information.currentTemperature && <>🥶 </> }
        { information.currentFeelsLikeTemperature > information.currentTemperature && <>🥵 </> }
        { information.currentFeelsLikeTemperature === information.currentTemperature && <>😀 </> }
        Gefühlte Temperatur: { information.currentFeelsLikeTemperature }ºC</p>
      <p>☝️ Gefühlte Tageshöchsttemperatur: { information.maxFeelsLikeTemperature }ºC</p>
      <p>👇 Gefühlte Tagestiefsttemperatur: { information.minFeelsLikeTemperature }ºC</p>

      { placeName ? <h3>KI-basierter Kleidungsvorschlag für { placeName }:</h3>
        : <h3>Aktuelles Wetter</h3> }
      { information.clothes.jacket !== "NONE" && <p>🧥 Jacke: { jacketName[information.clothes.jacket] }</p> }
      <p>👚 Oberteil: { topName[information.clothes.top] }</p>
      <p>👖 Hose: { pantName[information.clothes.pant] }</p>
      <p>👟 Schuhe: { shoeName[information.clothes.shoe] }</p>
      { information.clothes.headdress !== "NONE" &&
      <p>👒 Kopfbedeckung: { headdressName[information.clothes.headdress] }</p> }
      { !!information.clothes.accessories.length &&
      <p>☂️ Sonstiges: { information.clothes.accessories.map(a => accessoriesName[a]).join(", ") }</p>
      }
    </div>
  )
}

export {WeatherInformation}
