import axios from "axios";
import {API_KEY} from "../config/config";

type AddressComponent = {
  long_name: string
  short_name: string
}

export type Place = {
  geometry?: {
    location: {
      lat: number
      lng: number
    }
  }
  address_components: AddressComponent[]
}

export type PlaceDetailsResponse = {
  results: Place[]
}

export const getPlaceDetails = (address: string) =>
  axios.get<PlaceDetailsResponse>("https://maps.googleapis.com/maps/api/geocode/json", {
    params: {
      "address": address,
      "key": API_KEY,
    },
  })
