import React, {useEffect, useState} from 'react';
import {Form} from "./components/Form/Form";
import {loadMapApi} from "./utils/googleMapsUtils";
import {GlobalStyle} from './theme/GlobalStyle';
import {Headline, Surprise, Wrapper} from "./App.styles";

export const App = () => {

  // todo improve
  const [mapsScriptLoaded, setMapsScriptLoaded] = useState(false);

  useEffect(() => {
    const googleMapScript = loadMapApi();
    googleMapScript.addEventListener('load', function () {
      setMapsScriptLoaded(true);
    });
  }, []);

  if (!mapsScriptLoaded) {
    return <></>
  }
  // endtodo

  return (
    <Wrapper>
      <GlobalStyle/>
      <Headline>Brauch ich 'nen Pulli?</Headline>
      <Form/>
      <Surprise>{ "I <3 LLB" }</Surprise>
    </Wrapper>
  );
}
