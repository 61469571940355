import styled from "styled-components";
import {up} from "./theme/breakpoints";

export const Wrapper = styled.div`
  max-width: 70ch;
  margin: auto;
  line-height: 1.75;
  font-size: 1em;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  ${ up("sm") } {
    padding: 3em 1em;
    gap: 20px;
  }
`

export const Headline = styled.h1`
  margin-top: 0;
  line-height: 2.6rem;
`

export const Surprise = styled.p`
  color: white;
`
