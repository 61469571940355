import React, {KeyboardEvent, useRef, useState} from "react";
import {WeatherInformation} from "../WeatherInformation/WeatherInformation";
import {getWeatherInformation} from "../../provider/weatherInformationProvider";
import {WeatherInformation as WeatherInformationType} from "../../types/weatherInformation"
import {getPlaceDetails, Place} from "../../provider/placeDetailsProvider";
import {getAutocompleteSuggestions} from "../../provider/autocompleteProvider";
import {AutocompleteInput} from "./Form.styles";
import {WeatherInformationSkeleton} from "../WeatherInformation/Skeleton/WeatherInformationSkeleton";

export const Form = () => {

  const [information, setInformation] = useState<WeatherInformationType | null>()
  const [placeName, setPlaceName] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [suggestions, setSuggestions] = useState<string[]>([])

  const inputRef = useRef<HTMLInputElement>(null)

  const setDataFromPlaceResponse = (place: Place) => {
    setHasError(false)
    setIsLoading(true)
    getWeatherInformation(place.geometry?.location.lat, place.geometry?.location.lng)
      .then((r) => {
        setInformation(r.data)
        if (place.address_components && place.address_components[0]) {
          setPlaceName(place.address_components[0].long_name) // sollte aus Response kommen, wie bei anderem Endpoint dann auch
        }
      })
      .catch((_e) => {
        setInformation(null)
        setHasError(true);
      })
      .finally(() => setIsLoading(false))
  }

  const onSuggestionSelected = (suggestion: string) => {
    inputRef.current?.blur()

    getPlaceDetails(suggestion).then((r) =>
      setDataFromPlaceResponse(r.data.results[0]),
    )
  }

  const onEnter = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault()

      if (!suggestions.length) { // todo hier kann bei langsamer Verbindung noch nichts in den suggestions stehen, aber das verschafft ja zeit
        alert("Bitte Ort eingeben.")
        return
      }

      onSuggestionSelected(suggestions[0])
    }
  }

  const onInputChanged = (e: React.FormEvent<HTMLInputElement>) => {
    if (e.currentTarget.value === "") {
      setSuggestions([])
      return
    }

    if (suggestions.includes(e.currentTarget.value)) {
      onSuggestionSelected(e.currentTarget.value)
      return;
    }

    getAutocompleteSuggestions(e.currentTarget.value, (suggestions) => {
      setSuggestions(suggestions)
    })
  }

  return (<>
    <AutocompleteInput
      type={ "text" }
      onChange={ onInputChanged }
      onKeyDown={ onEnter }
      list={ "input-datalist" }
      ref={ inputRef }
      placeholder={ "Gib deinen Standort ein" }
    />

    { !!suggestions.length && (
      <datalist id={ "input-datalist" }>
        { suggestions.map((suggestion) => (
          <option key={ suggestion } value={ suggestion }>
            { suggestion }
          </option>
        )) }
      </datalist>
    ) }

    { isLoading &&
      <WeatherInformationSkeleton/>
    }

    { !isLoading && hasError &&
      <p>Sorry, da ist irgendwas ganz blödes passiert :(</p>
    }

    { !isLoading && !hasError && !!information &&
      <WeatherInformation information={ information } placeName={ placeName }/>
    }
  </>)
}
